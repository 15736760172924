import { ListItem, ListItemText, ListItemSecondaryAction, Button } from "@material-ui/core";
import React from "react";



export default function ProductListItem({ product, onAddToCart, cartItems }) {


    function alreadyInCart(product) {
        return cartItems.some(item => item.product.id === product.id);
    }

    function labelbutton(product) {
        if (alreadyInCart(product))
            return "+1";
        else
            return "In den Warenkorb";
    }
    function color(product) {
        if (alreadyInCart(product))
            return "secondary";
        else
            return "primary";
    }

    function cartItemCount() {
        if (alreadyInCart(product)) {
            return cartItems
                .filter(item => item.product.id === product.id)
                .reduce((a, b) => a + b.quantity, 0);
        } else {
            return 0;
        }
    }

    return (
        <ListItem divider>
            <ListItemText primary={product.name} secondary={("Im Warenkorb: ") + cartItemCount()} />
            <ListItemSecondaryAction>
                <Button variant="contained" color={(color(product))} size="small" onClick={() => onAddToCart(product)}>
                    {(labelbutton(product))}
                </Button>
            </ListItemSecondaryAction>
        </ListItem>
    );
}