import { createMuiTheme } from '@material-ui/core/styles'
const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#FBBA00',
        },
        secondary: {
            main: '#3F9AFB',
        },
    },
})
export default theme