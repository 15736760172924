import { createStyles, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from "prop-types";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        margin: {
            margin: theme.spacing(1),
        },
    }),
);

export function SearchField({ onChange,productFilter }: any) {
    const classes = useStyles();

    return (
        <div className={classes.margin}>
            <Grid container spacing={1} alignItems="flex-end">
                <Grid item>
                    <SearchIcon />
                </Grid>
                <Grid item>
                    <TextField
                        id="input-with-icon-grid"
                        label="Produktsuche"
                        value={productFilter}
                        onChange={onChange} 
                        />
                </Grid>
            </Grid>
        </div>
    );
}

SearchField.propTypes = {
    onChange: PropTypes.func.isRequired,
    productFilter: PropTypes.any.isRequired
};