import { Container, createStyles, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { OrderButton } from "./OrderButton";
import PlusOneIcon from '@material-ui/icons/PlusOne';
import ExposureNeg1Icon from '@material-ui/icons/ExposureNeg1';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
    }),
);



export function CartView({ cartItems, addToCart, removeFromCart, clearCart }) {
    const styleclasses = useStyles();

    function renderCartContent() {
        if (cartItems.length === 0)
            return (
                <div>
                    <Typography component="h5" align="center" color="textPrimary" display="inline" gutterBottom>
                        Keine Produkte im Warenkorb
                        <br />
                    </Typography>

                </div>
            )
        else
            return (
                <List className={styleclasses.root}>
                    {cartItems
                        .sort((a, b) => a.product.name.toLowerCase() < b.product.name.toLowerCase() ? -1 : 1)
                        .map((cartItem) => {
                            return (
                                <ListItem
                                    key={cartItem.product.id}
                                    button
                                    divider
                                >
                                    <ListItemText
                                        primary={cartItem.product.name}
                                        secondary={("Anzahl: ") + cartItem.quantity}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={() => addToCart(cartItem.product)}>
                                            <PlusOneIcon />
                                        </IconButton>
                                        <IconButton onClick={() => removeFromCart(cartItem.product)} >
                                            <ExposureNeg1Icon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            );
                        })}
                </List>
            )
    }
    return (
        <Container maxWidth="sm">
            <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                Warenkorb
            </Typography>
            <Grid
                container
                justify="center"
                spacing={1}
            >
                <Grid container item justify="center">
                    {renderCartContent()}
                </Grid>
                <Grid container item justify="center">
                    <OrderButton cartItems={cartItems} clearCart={clearCart} />
                </Grid>
            </Grid>
        </Container>
    );
}