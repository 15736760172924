import { createStyles, List, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import ProductListItem from "./ProductListItem";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
    }),
);

export default function ProductList({ products, onAddToCart, cartItems, productFilter }) {
    const styleclasses = useStyles();

    return (
        <List className={styleclasses.root} >
            {
                products
                    .filter(product => product.name.toLowerCase().search(productFilter.toLowerCase()) !== -1 && product.visible)
                    .sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
                    .map((value) => {
                        return (
                            <ProductListItem
                                product={value}
                                onAddToCart={onAddToCart}
                                cartItems={cartItems}
                                key={value.id}
                            />
                        );
                    })
            }
        </List>
    );
}

