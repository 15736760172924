import { Button, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import DeleteIcon from '@material-ui/icons/Delete';
import { setTimeout } from "timers";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
        },
    }),
);

export function OrderButton({ cartItems, clearCart }: any) {
    const classes = useStyles();

    function generateBody() {
        const br = "%0D%0A";

        var orderContent = "";


        cartItems
            .sort((a, b) => (a.quantity > b.quantity) ? 1 : -1)
            .forEach((cartItem: any) => {
                orderContent += "- " + cartItem.quantity + "x " + cartItem.product.name + br;
            });
        console.log("LOG ~ file: OrderButton.tsx ~ line 30 ~ .forEach ~ orderContent", orderContent);
        return `Abay! ${br}${br}Gerne würde ich folgendes Material bestellen: ${br} ${orderContent} ${br}${br} Besten Dank!`;

    }

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={clearCart}
                disabled={cartItems.length === 0}
            >
                Warenkorb leeren
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<ShoppingCartIcon />}
                href={"mailto:material@skema.ch?subject=Materialbestellung&body=" + generateBody()}
                onClick={(() => {
                    setTimeout(() => { clearCart(); }, 200);
                })}
                disabled={cartItems.length === 0}
            >
                E-Mail für Bestellung erstellen
            </Button>
        </React.Fragment>
    )

};
