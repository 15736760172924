import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL as string;

export async function loadProducts(completed = 0) {
  const serverResponse = await axios.get(API_URL, { params: { completed } });
  return serverResponse.data.result;
}

export function loadCartItems() {
  const cartItemsData = localStorage.getItem('cartItems');
  const cartItems = cartItemsData ? JSON.parse(cartItemsData) : [];
  return cartItems;
}

export function persistCartItems(cartItems) {
  localStorage.setItem('cartItems', JSON.stringify(cartItems));
}
