import { AppBar, Button, createStyles, makeStyles, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { Link } from 'react-router-dom';
import AuthenticationButton from "./AuthenticationButton";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles((theme: any) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        title: {
            flexGrow: 1,
        },
        link:{
            margin: theme.spacing(1)
        }
    }),
);

export function TopAppBar({ cartItems }) {
    const classes = useStyles();

    function cartItemCount() {
        return cartItems.reduce((a, b) => { return a + b.quantity; }, 0);
    }

    return (
        <div className={classes.root}>
            <AppBar position="relative" >
                <Toolbar>
                    <Typography variant="h6" color="inherit" noWrap className={classes.title}>
                        Material
                    </Typography>

                    <Button color="inherit" variant="outlined"component={Link} to="/" className={classes.link}>Produkte</Button>
                    <Button color="inherit" variant="outlined"component={Link} to="/cart" className={classes.link} startIcon={<ShoppingCartIcon />}>Warenkorb ({cartItemCount()})</Button>
                    <AuthenticationButton />
                </Toolbar>
            </AppBar>
        </div>
    );

};