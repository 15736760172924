import React, { useEffect, useState } from 'react';
import './App.css';
import 'fontsource-roboto';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { loadCartItems, loadProducts, persistCartItems } from './persistence';
import theme from './theme';
import { TopAppBar } from './components/TopAppBar';
import { Switch } from "react-router-dom";
import { ProductView } from './components/ProductView';
import { CartView } from './components/CartView';
import { Route } from "react-router-dom";

function App() {
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);


  useEffect(() => {
    async function load() {
      const products = await loadProducts();
      setProducts(products);
      setCartItems(loadCartItems());
    }
    load();
  }, []);

  function addToCart(product) {
    var cartIndex = cartItems.findIndex((item: any) => item.product.id === product.id);

    if (cartIndex !== -1) {
      const newList: any = cartItems.map((item: any) => {
        if (item.product.id === product.id) {
          item.quantity++;
          return item;
        }
        return item;
      });
      setCartItems(newList);
      persistCartItems(newList);
    } else {
      var newArray: any = [...cartItems, { "product": product, "quantity": 1 }];
      setCartItems(newArray);
      persistCartItems(newArray);
    }

  }

  function removeFromCart(product) {
    var cartIndex = cartItems.findIndex((item: any) => item.product.id === product.id);

    if (cartIndex !== -1) {
      const newList: any = cartItems.map((item: any) => {
        if (item.product.id === product.id) {
          item.quantity--;
          return item;
        }
        return item;
      });
      const updatedList: any = newList.filter(item => item.quantity !== 0);
      setCartItems(updatedList);
      persistCartItems(updatedList);
    }

  }

  function clearCart() {
    setCartItems([]);
    persistCartItems([])
  }



  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <TopAppBar cartItems={cartItems} />
      <Switch>
        <Route path="/cart"  >
          <CartView
            cartItems={cartItems}
            addToCart={addToCart}
            removeFromCart={removeFromCart}
            clearCart={clearCart}
          />
        </Route>

        <Route path="/"  >
          <ProductView
            products={products}
            cartItems={cartItems}
            onAddToCart={addToCart}
          />
        </Route>

      </Switch>
    </ThemeProvider>
  );
}

export default App;
