import React, { useEffect, useState } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import ProductList from "./ProductList";
import { SearchField } from "./SearchField";
import { useAuth0 } from '@auth0/auth0-react';
import Loading from "./Loading";



export function ProductView({ products, onAddToCart, cartItems, }) {
    const {
        isLoading,
        user,
        loginWithRedirect,
    } = useAuth0();
    const [productFilter, setProductFilter] = useState("");

    function searchInputChange(e) {
        setProductFilter(e.target.value);
    }


    useEffect(() => {
        (async function () {
            if (!isLoading && !user) {
                await loginWithRedirect();
            }
        })();
    }, [isLoading, user, loginWithRedirect]);

    if (!user)
        return <Loading />;
    else

        return (
            <Container maxWidth="sm" >
                <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                    Produkte
                </Typography>
                <Grid container justify="center" >
                    <SearchField onChange={searchInputChange} productFilter={productFilter} />
                    <ProductList products={products} productFilter={productFilter} cartItems={cartItems} onAddToCart={onAddToCart} />
                </Grid>
            </Container>
        );
}

